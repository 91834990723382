<template>
    <div class="bg-white rounded border-0 ssfr-panel">

        <button v-if="closeBtnShown" type="button" class="btn-close position-absolute mt-1 ms-1" data-bs-dismiss="modal" aria-label="Close"
            @click.prevent="$emit('close-btn-click')"></button>

        <img v-if="headerImg" :src="headerImg" alt="Header logo" class="sh-header-logo img-fluid rounded-top"
             style="width: 500px;"/>
        <img v-else-if="themedAssetUrlBase" :src="themedAssetUrlBase + '/email/Header.jpg'" alt="Header logo" class="sh-header-logo img-fluid rounded-top"
             style="width: 500px;"/>

        <div class="p-3 px-4" style="background-color: #f5f5f5;">
            <!-- user entered content here with different styling -->
            <div v-if="note" v-html="note" class="sc-rendered-markdown"></div>
            <div v-else>The following has been shared with you:</div>
        </div>

        <div style="background-color: #f5f5f5;" class="p-3">

            <div v-if="isShareExpired && !previewOnly">
                <div class="alert alert-warning">
                    Access to files has expired.
                    Email <a :href="'mailto:'+sharerEmail" rel="tooltip" target="_blank"
                       :title="'Email '+sharerEmail">{{sharerDisplayName}}</a> to request access.
                </div>
            </div>
            <div v-else-if="!resources || resources.length === 0" class="p-3">
                <div class="alert alert-warning">
                    This file is no longer available.
                    Email <a :href="'mailto:'+sharerEmail" rel="tooltip" target="_blank"
                      :title="'Email '+sharerEmail">{{sharerDisplayName}}</a> to request access.
                </div>
            </div>

            <div v-if="(!isShareExpired || previewOnly) && resources.length > 0" class="mb-3">
                <div v-for="resource in sortedResources" :key="resource.id">
                    <div v-if="resource.content_type === 'showcase_share'" class="row">
                        <div class="col-3 d-flex align-items-center justify-content-center">
                            <a v-if="presentationTitle" href="#" v-sc-tooltip="previewOnly?'Disabled in preview':'View the Showcase Presentation'"
                               @click.prevent="downloadOrOpenResource(resource)">
                                <img :src="presentationThumbUrl" :alt="presentationTitle" class="m-1 img-fluid"
                                     style="max-width: 60px; max-height: 60px;" /></a>
                        </div>
                        <div class="col-9 p-2 d-flex align-items-center">
                            <h5 v-if="presentationTitle">
                                <a href="#" v-sc-tooltip="previewOnly?'Disabled in preview':'View the Showcase Presentation'"
                                   @click.prevent="downloadOrOpenResource(resource)" class="text-break">
                                    {{presentationTitle}}
                                </a>
                            </h5>
                            <ScIcon v-if="presentationLoading" name="spinnerFW" class="text-muted"/>
                            <div v-if="!presentationTitle && !presentationLoading" class="text-muted">
                                <em>Presentation is unavailable.</em>
                            </div>
                        </div>
                    </div>
                    <div v-if="resource.content_type !== 'showcase_share'" class="row">
                        <div class="col-3 d-flex align-items-center justify-content-center">
                            <a v-if="resource.thumb_url && resource.url"
                               :href="resource.url" v-sc-tooltip="previewOnly?'Disabled in preview':'Download this file to your computer'"
                               @click.prevent="downloadOrOpenResource(resource)">
                                <img :src="resource.thumb_url" :alt="resource.name"
                                     style="max-width: 60px; max-height: 60px;" class="m-1 img-fluid">
                            </a>
                        </div>
                        <div class="col-9 p-2 d-flex align-items-center">
                            <h5 v-if="resource.name && resource.url">
                                <a :href="resource.url" v-sc-tooltip="previewOnly?'Disabled in preview':'Download this file to your computer'"
                                   @click.prevent="downloadOrOpenResource(resource)" class="text-break">
                                    <small><ScIcon name="download" class="me-1v text-muted"/></small>
                                    {{resource.name}}
                                </a>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-3 rounded-bottom">

            <div v-if="sharerEmail && (sharedType === 'link' || sharedType === 'email')" class="text-center">
                <small>Shared by
                <a :href="'mailto:' + sharerEmail " rel="tooltip"
                   @click.prevent="openExternally('mailto:' + sharerEmail)"
                   :title="'Email ' + sharerEmail ">{{sharerDisplayName}}</a></small>
            </div>

            <div v-if="daysRemaining !== null && daysRemaining >= 0" class="text-center">
                <small>Access expires
                <span v-if="daysRemaining === 0"> tomorrow</span>
                <span v-else-if="daysRemaining === 1"> in 1 day</span>
                <span v-else> in {{ daysRemaining }} days</span></small>
            </div>
            <div v-else-if="isShareExpired && daysRemaining !== null && daysRemaining < 0" class="text-center">
                <small class="text-danger">Access expired
                <span v-if="daysRemaining === -1"> today</span>
                <span v-else-if="daysRemaining === -2"> 1 day ago</span>
                <span v-else> {{ -1 * daysRemaining }} days ago</span></small>
            </div>

            <div class="text-center">
                <small>Powered by <a href="https://showcaseworkshop.com"
                     @click.prevent="openExternally('https://showcaseworkshop.com')">Showcase Workshop</a>
                    &middot; <a href="https://support.showcaseworkshop.com/help"
                                @click.prevent="openExternally('https://support.showcaseworkshop.com/help')">Help</a>
                </small>
            </div>
        </div>
    </div>
</template>

<script>

    import _ from 'underscore'; // sortBy
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
    import ScNativeExts from 'ScVueAliasScNativeExts';
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: "SharingSummaryForRecipient",
        emits: ['close-btn-click', 'open-showcase'],
        components: {ScIcon},
        props: {
            note: {type: String, default: null},
            resources: {type: Array, default: null},
            daysRemaining: {type: Number, default: 0},
            sharerDisplayName: {type: String, default: null},
            sharerEmail: {type: String, default: null},
            previewOnly: {type: Boolean, default: false},
            headerImg: {type: String, default: null},
            presentationLoading: {type: Boolean, default: false},
            presentationTitle: {type: String, default: null},
            presentationThumbUrl: {type: String, default: null},
            closeBtnShown: {type: Boolean, default: false},
            workspaceId: {type: Number, default: null},
            presentationId: {type: Number, default: null},
            sharedId: {type: Number, default: null},
            sharedType: {type: String, default: null},
            recordAnalytics: {type: Boolean, default: false},
            openAtOptions: {type: Object, default: null},
        },
        data() {
            return {
                themedAssetUrlBase: ScNativeExts.getThemedAssetUrl()
            }
        },
        methods: {
            downloadOrOpenResource(res) {
                if (this.previewOnly) return;
                if (res.content_type === 'showcase_share') {
                    this.$emit('open-showcase');
                    return;
                }
                if (this.recordAnalytics && this.sharedId) {
                    // record this in analytics log
                    ViewerEmbedHandler.recordScAnalyticsEvent('share_resource_download', {showcase_id: this.presentationId,
                        resource_id: res.id}, this.workspaceId, this.userId, this.sharedId);
                }
                this.openExternally(res.url);
            },
            openExternally: function(url) {
                ScNativeExts.openExternally(url, null);
            }
        },
        computed: {
            sortedResources() {
                if (!this.resources) return [];
                return _.sortBy(this.resources, (res) => {
                    let name = res.name;
                    if (name) name = name.toLowerCase();
                    if (res.content_type === 'showcase_share') name = '**** sort first';
                    return name;
                });
            },
            isShareExpired() {
                return this.daysRemaining !== null && this.daysRemaining < 0;
            }
        }
    }
</script>

<!-- Don't add <style scoped> here.  Vue adds to data-* the selectors that breaks markdown styles -->
<style>

    .sc-rendered-markdown blockquote {
        margin-left: 2em;
    }
    .sc-rendered-markdown pre {
        padding: 1em 1em 1em 2em;
        background-color: #eeeeee;
    }
    .sc-rendered-markdown table {
        margin-bottom: 1em;
    }
    .sc-rendered-markdown th, .sc-rendered-markdown td {
        border: 1px solid #dddddd;
    }

    .ssfr-panel {
        min-width: 260px;
        max-width: 500px;
    }

</style>

